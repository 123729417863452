import React, { useState, useEffect } from "react";

function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);

  function plusSlides(n) {
    setSlideIndex(slideIndex + n);
  }

  useEffect(() => {
    function showSlides() {
      let i;
      let slides = document.getElementsByClassName("slider-slide");
      if (slideIndex > slides.length) {
        setSlideIndex(1);
      }
      if (slideIndex < 1) {
        setSlideIndex(slides.length);
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      if (slides[slideIndex - 1] !== undefined) {
        slides[slideIndex - 1].style.display = "block";
      } else {
        if (slides.length > 0) {
          slides[0].style.display = "block";
        }
      }
    }
    showSlides();
  }, [slideIndex]);

  return (
    <section id="personality" className="slider-section">
      <button
        className="w3-button w3-black w3-left"
        onClick={() => plusSlides(-1)}
      >
        &#10094;
      </button>
      <div className="wrapper">
        <h2>Personality</h2>
        <div className="slider-slide fade">
          <div className="text">
            <strong>Music</strong>
            <br />
            Music is a big part of my life, and I spend a lot of my time
            listening to it. I'm drawn to the lyrics of songs because they often
            carry deep meaning. Depending on my mood, I'll select music that
            matches it, and I find that the lyrics of each song hold
            significance for me. I enjoy relating to the lyrics and finding
            personal connections within them.
            <br />
            <br />
            <strong>Books</strong>
            <br />
            When I have free time, I enjoy reading books because they offer so
            much to learn. Unfortunately, I don't get to read as often as I
            would like due to the demands of school and various projects that
            keep me busy.
            <br />
            <br />
            <strong>Hobbies</strong>
            <br />
            I know that music and books should be considered as hobbies, but
            those two categories are my life and what is keeping me alive. Other
            than that, I like to play video games and biking. From time to time
            I like to play different sports like football, basketball etc.
            <br />
          </div>
          <br />
          <div className="numbertext">1 / 4</div>
        </div>
        <div className="slider-slide fade">
          <div className="text">
            <strong>Childhood & Honesty</strong>
            <br />
            Although my childhood was challenging, I've always been driven to
            excel in everything I undertake. I have a strong sense of justice,
            recognizing that unfair treatment of people can be commonplace. In
            all situations, I prioritize honesty, even when it may be difficult
            or painful. For me, honesty is an essential trait that I value in
            others, and I struggle with those who are deceitful or dishonest.
            <br />
            <br />
            <strong>Anxiety</strong>
            <br />
            I tend to be a worrier, often plagued with anxiety and overthinking.
            I have a tendency to run through countless scenarios in my head,
            considering every possible outcome, which can be overwhelming.
            Trusting others can also be a challenge for me. In the past, my
            trust has been betrayed and I've been manipulated, leading me to
            become the person I am today, a fact that I find regrettable. <br />
            <br />
            <strong>Simplicity</strong>
            <br />
            A single good morning text from someone can make my day. I pay great
            attention to the details. Noticing the little things that people do
            for me, for which I am grateful. Spending money on others brings me
            greater satisfaction than spending it on myself.
            <br />
          </div>
          <br />

          <div className="numbertext">2 / 4</div>
        </div>
        <div className="slider-slide fade">
          <div className="text">
            <strong>Other Traits</strong>
            <br />
            I am a very friendly person and I love to help people. I am a very
            patient person, but at the same time I can get very inpatient. I am
            a very determined person and I never give up on anything. My goal is
            to always achieve the best scores and to try and be the #1 in
            everything. I am a very understanding/open-minded person and I try
            to understand people's point of view.
            <br />
            <br />
            <strong>Trust</strong>
            <br />
            You can trust me with your secrets - I'm someone who values
            confidentiality and will keep any shared information in strict
            confidence. Additionally, I'm a loyal individual who is always there
            for my loved ones, be it family or friends. Your trust is something
            I will never betray and if needed I will take your secrets to my
            grave. <br />
            Tho I rarely trust people and I can count on one hand the number of
            people I trust. Only a few intelectuals know my full story. (If you
            are one of them ♡) <br />
            <br />
            <strong>Identity</strong>
            <br />
            At present, I'm struggling with a sense of identity and am uncertain
            about who I truly am. Although I have clear goals for my life, I'm
            still trying to figure out the person I want to become. I'm
            dissatisfied with my current self, my life, and my physical
            appearance, and I'm working towards self-improvement. However, my
            motivation levels have been low, and it may take some time before I
            can make significant progress.
          </div>
          <br />

          <div className="numbertext">3 / 4</div>
        </div>
        <div className="slider-slide fade">
          <div className="text">
            <strong>Sexuality</strong>
            <br />
            Within my friend group, we have a running joke that I identify as a
            cooking pan. In all seriousness, I identify as pansexual. This means
            that a person's personality is a crucial factor for me when it comes
            to relationships. Tho maintaining good personal hygiene is also a
            priority for me, and I find it difficult to consider being in a
            relationship with someone who doesn't place importance on it.
            <br />
            <br />
            I am a very affectionate person and I love to cuddle. I could spend
            an entire night just cuddling with someone, just talking with the
            person and/or watching funny videos. I make sure that I listen to
            the person and that they are always happy and satisfied.
            <br />
            <br />
            <strong>Gender</strong>
            <br />
            Soon.
          </div>
          <br />
          <div className="numbertext">4 / 4</div>
        </div>
      </div>
      <button
        className="w3-button w3-black w3-right"
        onClick={() => plusSlides(1)}
      >
        &#10095;
      </button>
    </section>
  );
}

export default Slider;
