import React from "react"; // Import the React library
import { Link, useLocation } from "react-router-dom"; // Import the BrowserRouter, Link, Route, Switch, Redirect components
import { Link as SmoothScroll } from "react-scroll"; // Import the Link and animateScroll components

import "./navbar.css"; // Import the navbar.css file


const Navbar = () => {
  // Create a Navbar component
  const location = useLocation(); // Get the current location

  return (
    // Return the navbar
    <header>
      <div className="navbar_left">
        <img
          className="img-round"
          src="https://media.discordapp.net/attachments/839413152646168576/1165088437435641937/goofy_ahh_png.png?ex=65459429&is=65331f29&hm=6321725971ca6282946492b56c88e75daffcde62e67abd18c8bf47f5dbf07ae8&=&width=701&height=701"
          alt="pfp"
          id="pfp"
          width="70px"
          loading="lazy"
        ></img>
      </div>
      <div id="border">
        <nav>
          <ul className="nav__links">
            <li>
              <Link to="">Home</Link>
            </li>
            {location.pathname === "/" && (
              <li>
                <SmoothScroll to="about-me" smooth={"true"}>
                  About
                </SmoothScroll>
              </li>
            )}
            <li>
              <SmoothScroll to="/#projects" smooth={"true"}>
                Projects
              </SmoothScroll>
            </li>
            <li>
              <Link to="gallery" smooth={"true"}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/qoutes"></Link>Qoutes
            </li>
            <li>
              <Link to="/music"></Link>Music
            </li>
            <li>
              <Link to="/shows"></Link>Shows
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar; // Export the Navbar component
