import React from "react";
import Slider from "./slider";
import "./../../styles/welcome.css"; // Create a separate CSS file for styling if not already done

const welcome = () => (
  <main>
    <section id="intro">
      <h1>Hey, I'm Xylia ❣</h1>
      <p>
        My unmatched perspicacity coupled with sheer indefatigability makes me a
        feared opponent in any realm of human endeavor!
      </p>
    </section>
    <section id="about-me intro">
      <div className="split">
        <div className="split-1">
          <h2>About Me</h2>
          <p>
            A full-stack developer who mainly finds interests in{" "}
            <strong>System Administration and Network Management. </strong>I
            enjoy helping people with their projects and doing the things which
            people deem impossible. I love breaking the limits and pushing the
            boundaries of what's possible.
          </p>
          <p>
            In my free time I love listening to <strong>music</strong> and
            reading books. Most of the time I'm listening to{" "}
            <strong>music</strong> because that is how I cope with all the
            positive and all the negative things.
          </p>
          <a href="#personality">↓ More Info</a>
        </div>
        <img
          className="rounded"
          src="https://media.discordapp.net/attachments/839413152646168576/1165088437435641937/goofy_ahh_png.png?ex=65459429&is=65331f29&hm=6321725971ca6282946492b56c88e75daffcde62e67abd18c8bf47f5dbf07ae8&=&width=701&height=701"
          alt="pfp"
          loading="lazy"
        />
      </div>
    </section>

    <Slider />


    <section className="">
      <div className="wrapper uwu">
        <h1>What games do I play?</h1>
        <div className="cards">
          <div id="game0" className="card">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/5/51/Minecraft_cover.png"
              alt="minecraft"
              id="minecraft"
              loading="lazy" 
            />
            <h2>Minecraft</h2>
            <p>
              I'm a retired Minecraft veteran, particularly fond of the
              1.7.10/1.8.9 PvP era. I used to be a Minecraft aficionado,
              mastering the game's mechanics and intricacies. However, as
              Minecraft continued to evolve, my expertise waned.{" "}
            </p>
            <hr />
            <h3>Playtime: 15.000+ hours</h3>
          </div>
          <div id="game1" className="card">
            <img
              src="https://mlpnk72yciwc.i.optimole.com/cqhiHLc.IIZS~2ef73/w:auto/h:auto/q:75/https://bleedingcool.com/wp-content/uploads/2022/09/Omega-Strikers-Character-Lineup.jpg"
              alt="OmegaStrikers"
              id="OmegaStrikers"
              loading="lazy" 
            />
            <h2>Omega Strikers</h2>
            <p>
              Got addicted to this game and now I cannot get my self to remove
              this game from my devices. If anyone is reading this, please send
              help. It has taken over my life and my money- It is holding me
              hostage...
            </p>
            <hr />
            <h3>Playtime: 700 Hours</h3>
          </div>
          <div id="game2" className="card">
            <img
              src="https://i.pinimg.com/236x/ca/c4/b2/cac4b20cdcb0917acc2d9b619aad0b86.jpg"
              alt="valorant"
              id="valorant"
              loading="lazy" 
            />
            <h2>Valorant</h2>
            <p>
              I'm a Valorant enthusiast, embracing its competitive spirit even
              when it pushes me to the edge. The thrill of tactical gameplay and
              competitive matches keeps me coming back for more. Each round is a
              new challenge, and the journey just starts there.
            </p>
            <hr />
            <h3>Playtime: 600 hours</h3>
          </div>
          <div id="game3" className="card">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/d/db/Apex_legends_cover.jpg"
              alt="apex"
              id="apex"
              loading="lazy" 
            />
            <h2>Apex Legends</h2>
            <p>
              I struggle in this game, but I'm committed to improvement. My
              focus is on practicing movement, aim, and looting skills. I admit
              my current shortcomings, but I'm determined to evolve my gameplay
              by honing my movement & aim!{" "}
            </p>
            <hr />
            <h3>Playtime: 20 hours</h3>
          </div>
          <div id="game4" className="card">
            <img
              src="http://gamewallpapers.com/thumbnails_mob_325x520/wallpaper_league_of_legends_10.jpg"
              alt="league"
              id="legaue"
              loading="lazy" 
            />
            <h2>League of Legends</h2>
            <p>
              I'm a newcomer to this game, and while I may struggle, I'm
              determined to improve, mainly for the sake of sharing a good laugh
              with friends. I play whatever character my friends need!
            </p>
            <hr />
            <h3>Playtime: 100 hours</h3>
          </div>
          <div id="game5" className="card">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/6/6e/CSGOcoverMarch2020.jpg"
              alt="csgo"
              id="csgo"
              loading="lazy" 
            />
            <h2>CS:GO</h2>
            <p>
              I used to be a dedicated CS:GO player in my youth, with a focus on
              creating humorous clips and nailing those hospital-worthy flick
              shots. Those nostalgic CS:GO moments, filled with funny clips,
              remain some of my fondest gaming memories.
            </p>
            <hr />
            <h3>Playtime: Unknown</h3>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default welcome;
