import React from 'react'; // This is the default export
import ReactDOM from 'react-dom/client'; // This is the default export

import App from './App'; // Import app.js

const root = ReactDOM.createRoot(document.getElementById('root'));  // Create a root element
root.render( // Render the root element
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
