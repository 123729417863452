import React from 'react';

import a1 from '../../pages/gallery/temp/1.png';

import './gallery.css';

const Gallery = () => (
<div className="gallery">
  <figure className="gallery__item gallery__item--1">
    <img src={a1} className="gallery__img" alt="DisplayImage 1"/>
    <p>Text 1</p>
  </figure>
  <figure className="gallery__item gallery__item--2">
    <img src={a1} className="gallery__img" alt="DisplayImage 2"/>
    <p>Text 1</p>
  </figure>
  <figure className="gallery__item gallery__item--3">
    <img src={a1} className="gallery__img" alt="DisplayImage 3"/>
    <p>Text 1</p>
  </figure>
  <figure className="gallery__item gallery__item--4">
    <img src={a1} className="gallery__img" alt="DisplayImage 4"/>
    <p>Text 1</p>
  </figure>
  <figure className="gallery__item gallery__item--5">
    <img src={a1} className="gallery__img" alt="DisplayImage 5"/>
    <p>Text 1</p>
  </figure>
  <figure className="gallery__item gallery__item--6">
    <img src={a1} className="gallery__img" alt="DisplayImage 6"/>
    <p>Text 1</p>
  </figure>
</div>

);

export default Gallery;